.mapbox-container{
    margin: 20px;
    margin-left:auto;
    margin-right:auto;
    overflow: hidden;
}

.map-container {
    height: 400px;
}

.sidebar {
    background-color: #009879;
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    font-size: small;
    margin: 0px;
    border-radius: 4px;
}

.mapboxgl-control-container{
    display: none;
}

.marker {
    /*background-image: url('mapbox-icon.png'); */
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
}

.mapboxgl-popup {
    max-width: 200px;
}